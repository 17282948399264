import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { grey, tableBodyGrey } from 'common/styles/colors';
import { FC } from 'react';

const border = `1px solid ${grey}`;

const cellStyle = {
  minWidth: '40px',
  textAlign: 'center',
  padding: '3px 5px',
  color: tableBodyGrey,
  fontFamily: 'KanitRegular',
} as React.CSSProperties;

export const SizeEstimateRow: FC<{
  estimates: SizeEstimate[];
}> = ({ estimates }) => (
  <table style={{ border, width: '500px' }}>
    <thead>
      <tr>
        <th
          style={{
            ...cellStyle,
            borderRight: border,
            borderBottom: border,
          }}
        >
          Size
        </th>
        {estimates.map(estimate => (
          <th key={estimate.id} style={{ ...cellStyle }}>
            {estimate.size.value}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ ...cellStyle, borderRight: border }}>%</td>
        {estimates.map(estimate => (
          <td
            key={estimate.id}
            style={{ ...cellStyle, fontFamily: 'KanitLight' }}
          >
            {estimate.percentValue > 0 ? `${estimate.percentValue}` : '-'}
          </td>
        ))}
      </tr>
    </tbody>
  </table>
);
