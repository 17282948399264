import {
  CreateOrViewWrapper,
  DetailText,
} from 'common/components/DetailControls';
import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import useWindowSize from 'common/hooks/useWindowSize';
import { CmsDate } from 'common/models/cmsDate';
import { Estimate } from 'common/models/growerBlock/estimate';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import { HarvestProgress } from 'common/models/growerBlock/harvestProgress';
import { mobile } from 'common/styles/breakpoints';
import { grey, lighterNavy } from 'common/styles/colors';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { EstimatesHistoryTable } from './EstimatesHistoryTable';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))`
  div.col > div:first-of-type {
    div:first-of-type {
      width: 50%;
    }
  }
`;

const StyledCol = styled(Col).attrs(() => ({
  className: 'styled-column',
}))`
  @media (max-width: ${mobile}) {
    div.row > div {
      width: 170px;
    }
  }
`;

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ListTitle = styled.span`
  font-family: 'KanitSemiBold';
  font-size: 20px;
  width: 50%;
  color: ${lighterNavy};
`;

export const EstimatesFields: FC<{
  blockSeasonId?: number;
  conversionRate?: number | null;
  harvestProgress?: HarvestProgress | null;
  estimates?: Estimate[];
}> = ({ blockSeasonId, conversionRate, harvestProgress, estimates }) => {
  const { width } = useWindowSize();
  const { userHasPermission } = useRbac();
  const isMobile = width < parseInt(mobile, 10);
  const {
    formState: { errors },
  } = useFormContext<HarvestEstimate>();

  const formatProgressValue = (value?: number | null, isPercent = false) => {
    const isPresent = typeof value === 'number';
    return `${isPresent ? value : '-'}${isPresent && isPercent ? '%' : ''}`;
  };
  const formatSideDate = (value?: CmsDate | null) =>
    value ? `(${value.toDateString()})` : '';

  return (
    <CreateOrViewWrapper
      formObject={harvestProgress && Object.keys(harvestProgress).length !== 0}
      message='No harvest estimates exist for this season.'
      canCreate={
        userHasPermission('evalEstTabs:create/edit') && !!blockSeasonId
      }
    >
      <RowWrapper
        style={{
          justifyContent: 'center',
          marginTop: '10px',
          borderBottom: `1px solid ${grey}`,
        }}
      >
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Latest Bin Estimate'
              name='estimatedBins'
              validation={errors.estimatedBins?.message}
              withSeparator={false}
            />
          </StyledCol>
        </StyledRow>
      </RowWrapper>
      <RowWrapper style={{ marginTop: '20px' }}>
        <ListTitle style={{ marginLeft: '20px' }}>CMS Progress</ListTitle>
        <ListTitle style={{ display: isMobile ? 'none' : 'flex' }}>
          Spokane Progress
        </ListTitle>
      </RowWrapper>
      <StyledStack>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Bins Picked'
              editable={false}
              text={`${formatProgressValue(
                harvestProgress?.internalBinsPicked,
              )}            ${formatSideDate(
                CmsDate.parse(harvestProgress?.latestPickDate),
              )}`}
            />
          </StyledCol>
          <StyledCol>
            <ListTitle
              style={{
                display: isMobile ? 'flex' : 'none',
                margin: '20px 0 0 20px',
              }}
            >
              Spokane Progress
            </ListTitle>
            <DetailText
              label='Bins Picked'
              editable={false}
              text={`${formatProgressValue(
                harvestProgress?.externalBinsPicked,
              )}            ${formatSideDate(
                CmsDate.parse(harvestProgress?.latestHarvestDate),
              )}`}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Bins To Harvest'
              editable={false}
              text={formatProgressValue(harvestProgress?.internalBinsLeft)}
            />
          </StyledCol>
          <StyledCol>
            <DetailText
              label='Bins To Harvest'
              editable={false}
              text={formatProgressValue(harvestProgress?.externalBinsLeft)}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Harvest Phase'
              editable={false}
              text={formatProgressValue(
                harvestProgress?.internalHarvestPhase,
                true,
              )}
            />
          </StyledCol>
          <StyledCol>
            <DetailText
              label='Harvest Phase'
              editable={false}
              text={formatProgressValue(
                harvestProgress?.externalHarvestPhase,
                true,
              )}
            />
          </StyledCol>
        </StyledRow>
      </StyledStack>
      <EstimatesHistoryTable
        conversionRate={conversionRate}
        estimates={estimates}
      />
    </CreateOrViewWrapper>
  );
};
