import {
  blueShadeHeader,
  grey,
  lighterBlueShade,
  lightGreyBackground,
  secondaryWhite,
  shadowGrey,
  tableBodyGrey,
  tableHeaderGrey,
} from 'common/styles/colors';
import styled from 'styled-components';

export const TableContainer = styled.div`
  box-shadow: 0px 3px 12px ${shadowGrey};
  border-radius: 8px;
  background-color: ${secondaryWhite};
  padding: 13px;
  width: 100%;

  h3 {
    font-family: KanitLight;
    font-size: 32px;
    line-height: 180%;
    letter-spacing: 0.0075em;
    color: ${blueShadeHeader};
    margin-right: 5px;
  }

  .scrollable-container {
    overflow-x: scroll;
  }

  table {
    width: 100%;
  }

  th,
  tfoot td {
    font-family: KanitSemiBold;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: ${tableHeaderGrey};
    white-space: nowrap;
    padding: 6px;
  }

  td {
    font-family: KanitLight;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: ${tableBodyGrey};
    padding: 10px 6px;
  }

  tfoot {
    border-top: solid ${grey} 1px;
  }

  th {
    border-bottom: 1px solid ${grey};
  }

  .blue-row {
    background-color: ${lighterBlueShade};
  }

  .clickable-row {
    &:hover {
      cursor: pointer;
      background-color: ${lightGreyBackground};
      opacity: 0.9;
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileTableContainer = styled.div`
  width: 100%;

  table {
    width: 100%;
  }

  h3 {
    padding: 15px 0 0px 20px;
    font-family: KanitLight;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.0075em;
    color: ${blueShadeHeader};
  }

  thead {
    border-bottom: 1px solid ${grey};
    svg {
      color: ${grey};
    }
  }

  th {
    font-family: KanitSemiBold;
    font-size: 16px;
    font-weight: normal;
    line-height: 138%;
    letter-spacing: 0.0075em;
    white-space: nowrap;
    padding: 6px;
    color: ${tableHeaderGrey};
  }

  td {
    font-family: KanitLight;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: ${tableBodyGrey};
    padding: 15px 6px;
  }

  .blue-row {
    background-color: ${lighterBlueShade};
  }

  .no-data-text {
    padding: 15px 0 0 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DataTableText = styled.span`
  display: flex;
  font-family: KanitLight;
  font-size: 16px;
  color: ${tableBodyGrey};
`;

export const MobileStyling = styled.div`
  tbody {
    tr {
      td:nth-last-child(-n + 2) {
        font-weight: bold;
      }
    }
  }
`;

export const LoadingOrEmpty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;
