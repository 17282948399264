import { yupResolver } from '@hookform/resolvers/yup';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import { ChangeStatusModals } from 'common/components/ChangeStatusModals/ChangeStatusModals';
import { DetailForm } from 'common/components/DetailControls/DetailForm';
import FormPrompt from 'common/components/FormPrompt';
import {
  LocationButton,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import { NestedTabs } from 'common/components/NestedTabs/NestedTabs';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { lightGreyText, orange } from 'common/styles/colors';
import { FormPadding, StyledFormWrapper } from 'common/styles/form';
import { BlockDetailsFooter } from 'features/grower-views/components/BlockDetails/BlockDetailsFooter';
import {
  BlockInfo,
  BlockInfoWrapper,
} from 'features/grower-views/components/BlockDetailsForm';
import { DetailsHeader } from 'features/grower-views/components/DetailsHeader';
import { useRbac } from 'features/rbac';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { createBlockFormValues } from 'utils/formValues/blockDetails';
import { createEvalFormValues } from 'utils/formValues/evaluation';
import { createLatestEstimateFormValues } from 'utils/formValues/latestEstimate';
import { createMonitoringFormValues } from 'utils/formValues/monitoring';
import { createSizeFormValues } from 'utils/formValues/sizeEstimates';
import { blockSchema } from 'utils/schemas/blockSchema';
import { estimatesSchema } from 'utils/schemas/estimatesSchema';
import { evaluationSchema } from 'utils/schemas/evaluationSchema';
import { monitoringSchema } from 'utils/schemas/monitoringSchema';
import { sizeEstimatesSchema } from 'utils/schemas/sizeEstimatesSchema';
import * as yup from 'yup';
import useBlockEvalActions from '../hooks/useBlockEvalActions';
import { BlockEvalFormData } from '../types';
import { blockEvalTabs } from './BlockEvalTabs';

const LargeFormWrapper = styled(StyledFormWrapper)`
  max-width: 930px;
  width: 100%;

  /* Undo form padding START */
  padding: 20px 0;

  .status-wrapper,
  .block-info-wrapper,
  .styled-row {
    padding: 0 20px;
  }

  .form-top-bar {
    padding: 0 20px 5px;
  }

  @media (max-width: ${mobile}) {
    width: 100vw;
  }
`;

/**
 * Handles form submission for all block evaluation tabs.
 *
 * @remarks
 *
 * This form works by defining a single form wrapper that houses all tab fields
 * at once. The fields are hidden or shown depending on the selected tab, but
 * they are always present.
 *
 * Because of this, from the perspective of `useForm`, this is one big form,
 * instead of a separate form per tab. This means that:
 *
 * - default values passed to `useForm` must include all fields, across tabs,
 *   as a single flattened object. This is the reason why we remove some common
 *   fields that would conflict, such as `createdBy`.
 *
 * - we need logic to determine what to validate on submit, which is why the
 *   form resolver switches between different `yup` schemas.
 *
 * - we also need to switch on which updater to use on submit.
 *
 * The reason for this design is that it made it easier to switch between tabs,
 * especially without having to refresh the page as the user tabs through. We
 * might revisit this design if managing all tabs at once becomes too onerous.
 */
export const BlockEvalForm: FC<{
  block: BlockDetails<WithAllSeasonData>;
}> = ({ block }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { userHasPermission } = useRbac();
  const {
    updateBlock,
    updateHarvestEstimate,
    updateEvaluation,
    updateSizeEstimates,
    updateOrchardMonitoring,
  } = useBlockEvalActions();
  const selectedTabPath = pathname.substring(url.length);
  const [isEdit, setIsEdit] = useState(false);

  // For modals
  const [isInactiveOpen, showInactiveModal] = useState(false);
  const [isActiveOpen, showActiveModal] = useState(false);

  const { blockId, latitude, longitude, grower, seasonData } = block;

  const mapParams = validateCoordinateInfo(latitude, longitude, blockId);

  const { data: varieties } = useGetVarietiesQuery();

  /** Object containing all fields across all tabs */
  const formFields = useMemo(() => {
    return {
      ...createBlockFormValues(block),
      ...createEvalFormValues(seasonData?.evaluation),
      sizeEstimates: createSizeFormValues(block, varieties),
      ...createLatestEstimateFormValues(seasonData?.harvestProgress),
      ...createMonitoringFormValues(seasonData?.monitoring),
    };
  }, [block, seasonData, varieties]);

  const methods = useForm<BlockEvalFormData, { selectedTabPath: string }>({
    defaultValues: formFields,
    context: { selectedTabPath },
    resolver: (data, context, options) => {
      switch (context?.selectedTabPath) {
        case Constants.routes.DETAILS:
          return yupResolver(blockSchema)(data, context, options);
        case Constants.routes.EVALUATION:
          return yupResolver(evaluationSchema)(data, context, options);
        case Constants.routes.SIZES:
          return yupResolver(sizeEstimatesSchema)(data, context, options);
        case Constants.routes.ESTIMATES:
          return yupResolver(estimatesSchema)(data, context, options);
        case Constants.routes.MONITORING:
          return yupResolver(monitoringSchema)(data, context, options);
        default:
          return yupResolver(yup.object({}))(data, context, options);
      }
    },
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    trigger,
  } = methods;

  const trySubmit = useCallback(
    async (event: React.FormEvent) => {
      let updater: (formData: BlockEvalFormData) => Promise<void>;
      let isSuccessful: boolean;

      switch (selectedTabPath) {
        case Constants.routes.DETAILS:
          updater = updateBlock;
          break;
        case Constants.routes.EVALUATION:
          updater = updateEvaluation;
          break;
        case Constants.routes.SIZES:
          updater = updateSizeEstimates;
          break;
        case Constants.routes.ESTIMATES:
          updater = updateHarvestEstimate;
          break;
        case Constants.routes.MONITORING:
          updater = updateOrchardMonitoring;
          break;
        default:
          return true;
      }

      if (isDirty && isValid) {
        try {
          await handleSubmit(updater)(event);
          isSuccessful = true;
        } catch (error) {
          isSuccessful = false;
        }
      } else if (!isValid) {
        trigger();
        notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
        isSuccessful = false;
      } else {
        isSuccessful = true; // No changes, continue normally.
      }

      return isSuccessful;
    },
    [
      selectedTabPath,
      isDirty,
      isValid,
      updateBlock,
      updateEvaluation,
      updateSizeEstimates,
      updateHarvestEstimate,
      updateOrchardMonitoring,
      handleSubmit,
      trigger,
    ],
  );

  useEffect(() => {
    reset(formFields);
  }, [formFields, reset]);

  return (
    <>
      {/* Modals are instantiated here to avoid their submit actions
    from submitting the DetailForm's form. */}
      <ChangeStatusModals
        isActiveOpen={isActiveOpen}
        isInactiveOpen={isInactiveOpen}
        showInactiveModal={showInactiveModal}
        showActiveModal={showActiveModal}
        block={block}
      />
      <FormProvider {...methods}>
        <DetailForm
          submitAction={trySubmit}
          disabled={isSubmitting}
          CustomWrapper={LargeFormWrapper}
          largeLabels
          setIsEdit={setIsEdit}
          reset={reset}
          closeHandler={() => history.push(Constants.routes.EVAL_EST_MAIN)}
          canEdit={
            userHasPermission('evalEstTabs:create/edit') && !!seasonData?.id
          }
        >
          <DetailsHeader status={seasonData?.status} type='block' />
          <BlockInfoWrapper>
            <BlockInfo>{grower.growerId}</BlockInfo>
            <BlockInfo>
              {blockId}
              <LocationButton
                backgroundColor={orange}
                iconColor={lightGreyText}
                mapParams={mapParams}
              />
            </BlockInfo>
          </BlockInfoWrapper>
          <NestedTabs
            tabs={blockEvalTabs(block, [...formFields.sizeEstimates])}
            isDisabled={isEdit}
          />
          {pathname.substring(url.length) === Constants.routes.DETAILS &&
            !isEdit && (
              <div style={{ padding: `0 ${FormPadding}` }}>
                <BlockDetailsFooter
                  block={block}
                  showInactiveModal={showInactiveModal}
                  showActiveModal={showActiveModal}
                  sizeEstimates={formFields.sizeEstimates}
                />
              </div>
            )}
        </DetailForm>
        <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
      </FormProvider>
    </>
  );
};
