import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { DetailTabHeader } from 'common/components/DetailControls/DetailTabHeader';
import { NestedTab } from 'common/components/NestedTabs/NestedTabs';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { BlockDetailsFields } from 'features/grower-views/components/BlockDetails/BlockDetailsFields';
import { Constants } from 'utils/constants';
import { EstimatesFields } from './fields/EstimatesFields';
import { EvaluationFields } from './fields/EvaluationFields';
import { MonitoringFields } from './fields/MonitoringFields';
import { SizesForm } from './SizesForm';

export const blockEvalTabs: (
  block: BlockDetails<WithAllSeasonData>,
  sizeEstimates: SizeEstimate[],
) => NestedTab[] = (block, sizeEstimates) => [
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Details' />
    ),
    view: () => <BlockDetailsFields block={block} />,
    tabPath: Constants.routes.DETAILS,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Evaluation' />
    ),
    view: () => (
      <EvaluationFields
        blockSeasonId={block.seasonData?.id}
        evaluation={block.seasonData?.evaluation}
      />
    ),
    tabPath: Constants.routes.EVALUATION,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Sizes' />
    ),
    view: () => (
      <SizesForm
        blockSeasonId={block.seasonData?.id}
        sizeEstimates={sizeEstimates}
      />
    ),
    tabPath: Constants.routes.SIZES,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Estimates' />
    ),
    view: () => (
      <EstimatesFields
        blockSeasonId={block.seasonData?.id}
        harvestProgress={block.seasonData?.harvestProgress}
        estimates={block.seasonData?.estimates}
        conversionRate={block.variety?.binConversion}
      />
    ),
    tabPath: Constants.routes.ESTIMATES,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Orchard Monitoring' />
    ),
    view: () => (
      <MonitoringFields
        blockSeasonId={block.seasonData?.id}
        monitoring={block.seasonData?.monitoring}
      />
    ),
    tabPath: Constants.routes.MONITORING,
  },
];
